<!--
--------------------------------------------------------------------------
   ItemStaff.vue
--------------------------------------------------------------------------

    Component to print the organization staff

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-staff" v-if="people.length > 0 || morePeople.length > 0">
        <b-tabs content-class="mt-3 pl-2 pr-2" justified class="mt-5">
            <b-tab active lazy v-if="people.length > 0">
                <template #title>
                    <i class="fas fa-user"></i> <translate>People</translate>
                </template>
                <ul class="triangle-list" :class="{ 'list-4-columns' : people.length > 10 }">
                    <li v-for="(person, index) in people" :key="'item.' + item.id + '.person.' + index">
                        <router-link :to="'/' + person[1]" v-if="person[3] !== '1' && person[1] !== undefined && person[1] !== '0'">
                            {{ person[0] }}
                        </router-link>
                        <span v-else-if="person[3] === '1'">
                            {{ person[0] }}<br /><translate>External to the UPC</translate>
                        </span>
                        <span v-else>
                            {{ person[0] }}
                        </span>
                        <span v-if="person[2] === '1' && item.subtype !== 'campus'"><br /><translate>Manager</translate></span>
                    </li>
                </ul>
            </b-tab>
            <b-tab lazy v-if="morePeople.length > 0">
                <template #title>
                    <i class="fas fa-user-friends"></i> <translate>More people</translate>
                </template>
                <ul class="triangle-list" :class="{ 'list-4-columns' : morePeople.length > 10 }">
                    <li v-for="(person, index) in morePeople" :key="'item.' + item.id + '.person.' + index">
                        <router-link :to="'/' + person[1]" v-if="person[3] !== '1' && person[1] !== undefined && person[1] !== '0'">
                            {{ person[0] }}<span v-if="person[5] !== ''"><br />(<translate>until</translate> {{ person[5] }})</span>
                        </router-link>
                        <span v-else>
                            {{ person[0] }}<span v-if="person[5] !== ''"><br />(<translate>until</translate> {{ person[5] }})</span>
                        </span>
                    </li>
                </ul>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
    export default {
        name: 'item-staff',
        props: ['item'],
        data() {
            return {
                people: [],
                morePeople: []
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Get and sort people and more people
                let allPeople = this.getItemFieldValue(this.item, 'person', this.$language.current, true);
                let peopleAdded = [];
                this.people = [];
                this.morePeople = [];
                allPeople.forEach(p => {
                    let personData = p.split('##');
                    // Check if person are already added
                    if (!peopleAdded.includes(personData[0])) {
                        peopleAdded.push(personData[0]);
                        if (personData[4] !== '0') {
                            this.people.push(personData);
                            if (personData[3] !== '1' && personData[1] !== undefined && personData[1] !== '0') this.checkItem(personData[1], 'people');
                        } else {
                            this.morePeople.push(personData);
                            if (personData[3] !== '1' && personData[1] !== undefined && personData[1] !== '0') this.checkItem(personData[1], 'morePeople');
                        }
                    } else if (personData[4] !== '0') {
                        this.morePeople = this.morePeople.filter(p => p[0] != personData[0]);
                        this.people.push(personData);
                    }
                });

                // Sort persons arrays
                this.people.sort(function (a, b) {
                   return a[0].localeCompare(b[0]);
                });

                this.morePeople.sort(function (a, b) {
                    return a[0].localeCompare(b[0]);
                });
            },
          /**
           * Check if item exists
           */
          checkItem(itemId, peopleArray) {
              // Get names of identifier fields
              let identifiers = [...new Set(this.$store.state.fields.filter(f => f.ident !== 0).map(f => {
                if (this.specialFields.includes(f.name)) return f.name;
                else return this.getFieldname(f.name, f.itemType);
              }))];

              // Prepare search query
              let formData = { query: ['accessURL_str:(' + this.filterQuery(this.$store.state.config.URL + '/' + itemId + '##*') + ' OR ' + this.filterQuery(this.$store.state.config.URL + '/' + itemId + '##*') + ')'], facets: [], page: { pageIdx: 0, pageSize: 2 }, sort: 'subtype desc' };
              identifiers.forEach(i => formData.query.push(i + '_str:"' + this.filterQuery(itemId) + '"'));
              formData.query = formData.query.join(' OR ');

              // Search items
              let vm = this;
              this.axios.post(this.apiURL + '/items/search', formData)
                  .then(response => {
                      // Item not found
                      if (response.data.content.length == 0) {
                          // Update array data
                          if (peopleArray == 'people') {
                              let index = vm.people.findIndex(p => p[1] == itemId);
                              if (index != -1) {
                                  let personData = vm.people[index];
                                  personData[1] = '0';
                                  vm.people.splice(index, 1, personData);
                              }
                          } else {
                              let index = vm.morePeople.findIndex(p => p[1] == itemId);
                              if (index != -1) {
                                  let personData = vm.morePeople[index];
                                  personData[1] = '0';
                                  vm.morePeople.splice(index, 1, personData);
                              }
                          }
                      }
                  })
                  .catch(error => {
                      this.processError(error);
                  });
          }
        },
        watch: {
            /**
             * Refresh data when item changes
             */
            item() {
                this.init();
            }
        }
    }
</script>