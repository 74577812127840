<!--
--------------------------------------------------------------------------
   Changelog.vue
--------------------------------------------------------------------------

    Component to build changelog view

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="news" class="view">
        <h1 v-translate>News</h1>
        <loading v-if="loading"></loading>
        <div v-else>
            <div v-for="year in Object.keys(changelog).sort((a, b) => b - a)" :key="year">
                <div v-for="month in Object.keys(changelog[year])" :key="month" style="clear: both;">
                    <h2 class="mb-4">{{ $gettext(month) }} {{ year }}</h2>
                    <template v-if="changelog[year][month].public.length > 0">
                        <ul class="triangle-list">
                            <li v-for="entry in changelog[year][month].public" :key="entry.id" v-html="entry.text[$language.current]" class="changelog-entry"></li>
                        </ul>
                        <br />
                    </template>
                </div>
            </div>
            <div class="text-center pt-4 pb-4 mt-3 no-results" v-if="Object.keys(changelog).length == 0">
                <translate>No news found</translate>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from '@/components/Loading';

    export default {
        name: 'changelog',
        components: { Loading },
        data: function() {
            return {
                loading: true,
                changelog: {}
            };
        },
        mounted() {
            this.get();

            // Set metadata
            this.setMetadata(this.$gettext('News') + ' | ' + this.$gettext(this.$store.state.metadata.title), 'List of updates and new features of the portal', this.$gettext('news') + ',' + this.$gettext(this.$store.state.metadata.keywords));

            // Set base path
            this.$store.commit('setCurrentPath', '/news');
        },
        methods: {
            /**
             * Get changelog entries
             */
            get() {
                // Set loading
                this.loading = true;

                // Reset results
                this.changelog = {};

                // Search changelog
                this.axios.get(this.apiURL + '/changelog')
                    .then(response => {
                        this.loading = false;
                        let vm = this;
                        response.data.forEach(el => {
                            // Get year and month
                            let date = new Date(el.date);
                            let year = date.getFullYear();

                            // If not exists, create key
                            if (!vm.changelog[year]) vm.changelog[year] = {};
                            let month = date.toLocaleString('en', { month: 'long' });

                            // If not exits create key
                            if (!vm.changelog[year][month]) {
                                vm.changelog[year][month] = { public : [], admin: [] };
                            }

                            // Add changelog entry to array related to public flag
                            if (el.public) vm.changelog[year][month].public.push(el);
                            else vm.changelog[year][month].admin.push(el);
                        });
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        }
    }
</script>