<!--
--------------------------------------------------------------------------
   ItemFiles.vue
--------------------------------------------------------------------------

    Component to print the activity files

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-files" v-if="files.length > 0">
        <h3 v-translate>Attachments</h3>
        <ul class="triangle-list">
            <li v-for="(file, index) in files" :key="'item.' + item.id + '.file.' + index">
                <a :href="file[1]" target="_blank" :alt="file[0]" :title="file[0]">{{ file[0] }}</a> ({{ (file[3] / 1048576).toFixed(2) }} MB)
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'item-files',
        props: ['item'],
        data() {
            return {
                files: []
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Sort item files
                if (this.item.file !== null) {
                    this.files = this.item.file.map(f => f.split('##'))
                        .sort(function (a, b) {
                            return a[2] > b[2] ? 1 : -1;
                        });
                }
            }
        },
        watch: {
            /**
             * Refresh data when item changes
             */
            item() {
                this.init();
            }
        }
    }
</script>