<!--
--------------------------------------------------------------------------
   ItemProfileTools.vue
--------------------------------------------------------------------------

    Component to print the item profile tools

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-profile-tools text-right">
        <ul class="list-inline" v-if="item.type === 'person'">
            <!-- Orcid -->
            <li class="list-inline-item" v-if="orcidUrl !== ''">
                <a :href="orcidUrl" target="_blank" rel="nofollow" :title="'ORCID'|translate"><img src="/images/tools/orcid.png" :alt="'ORCID'|translate"></a>&nbsp;<a :href="orcidUrl" target="_blank" :title="'ORCID'|translate"><translate>ORCID</translate></a>
            </li>
            <!-- Scopus Id -->
            <li class="list-inline-item" v-if="scopusUrl !== ''">
                <a :href="scopusUrl" target="_blank" rel="nofollow" :title="'Scopus Author ID'|translate"><img src="/images/tools/scopus.png" :alt="'Scopus Author ID'|translate"></a>&nbsp;<a :href="scopusUrl" target="_blank" :title="'Scopus Author ID'|translate"><translate>Scopus Author ID</translate></a>
            </li>
            <!-- Wok Id -->
            <li class="list-inline-item" v-if="wokUrl !== ''">
                <a :href="wokUrl" target="_blank" rel="nofollow" :title="'Researcher ID - Publons'|translate"><img src="/images/tools/wok.png" :alt="'Researcher ID - Publons'|translate"></a>&nbsp;<a :href="wokUrl" target="_blank" :title="'Researcher ID - Publons'|translate"><translate>Researcher ID - Publons</translate></a>
            </li>
            <!-- Apren -->
            <li class="list-inline-item" v-if="aprenUrl !== ''">
                <a :href="aprenUrl" target="_blank" rel="nofollow" :title="'Aprèn pofile'|translate"><img src="/images/tools/apren.png" :alt="'Aprèn profile'|translate"></a>&nbsp;<a :href="aprenUrl" target="_blank" :title="'Aprèn profile'|translate"><translate>Aprèn</translate></a>
            </li>
            <!-- Libraries -->
            <li class="list-inline-item" v-if="librariesUrl !== ''">
                <a :href="librariesUrl" target="_blank" rel="nofollow" :title="'Libraries catalogue'|translate"><img src="/images/tools/catalog.png" :alt="'Libraries catalogue'|translate"></a>&nbsp;<a :href="librariesUrl" target="_blank" :title="'Libraries catalogue'|translate"><translate>Libraries catalogue</translate></a>
            </li>
            <!-- Addthis -->
            <li class="list-inline-item">
                <a href="http://www.addthis.com/bookmark.php?v=300&pubid=ra-504710d1176e7d1f" class="addthis_button" :title="'Share'|translate" :addthis:url="getItemURL(item)" :addthis:title="getItemFieldValue(item, 'title', $language.current, false)">
                    <img src="/images/tools/share.png" :alt="'Share'|translate">&nbsp;<translate>Share</translate>
                </a>
            </li>
            <!-- QR -->
            <li class="list-inline-item">
                <a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><img src="/images/tools/qr.png" :alt="'QR Code'|translate"></a>&nbsp;<a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><translate>QR Code</translate></a>
            </li>
            <!-- Help -->
            <li class="list-inline-item">
                <a href="javascript:void(0);" v-b-modal.profile-info :title="'Information'|translate"><img src="/images/tools/information.png" :alt="'Information'|translate"></a>&nbsp;<a href="javascript:void(0);" v-b-modal.profile-info :title="'Information'|translate"><translate>Information</translate></a>
            </li>
        </ul>
        <ul class="list-inline" v-if="item.type === 'organization'">
            <!-- Contact -->
            <li class="list-inline-item" v-if="contactUrl !== ''">
                <a :href="contactUrl" target="_blank" rel="nofollow" :title="'Contact data'|translate"><img src="/images/tools/contact.png" :alt="'Contact data'|translate"></a>&nbsp;<a :href="contactUrl" target="_blank" :title="'Contact data'|translate"><translate>Contact data</translate></a>
            </li>
            <!-- Addthis -->
            <li class="list-inline-item">
                <a href="http://www.addthis.com/bookmark.php?v=300&pubid=ra-504710d1176e7d1f" class="addthis_button" :title="'Share'|translate" :addthis:url="getItemURL(item)" :addthis:title="getItemFieldValue(item, 'title', $language.current, false)">
                    <img src="/images/tools/share.png" :alt="'Share'|translate">&nbsp;<translate>Share</translate>
                </a>
            </li>
            <!-- QR -->
            <li class="list-inline-item">
                <a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><img src="/images/tools/qr.png" :alt="'QR Code'|translate"></a>&nbsp;<a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><translate>QR Code</translate></a>
            </li>
            <!-- Help -->
            <li class="list-inline-item">
                <a href="javascript:void(0);" v-b-modal.profile-info :title="'Information'|translate"><img src="/images/tools/information.png" :alt="'Information'|translate"></a>&nbsp;<a href="javascript:void(0);" v-b-modal.profile-info :title="'Information'|translate"><translate>Information</translate></a>
            </li>
        </ul>
        <ul class="list-inline" v-if="item.type === 'activity'">
            <!-- Fulltext -->
            <li class="list-inline-item" v-if="fulltextUrl !== ''">
                <a :href="fulltextUrl" target="_blank" rel="nofollow" :title="'Access to the full text'|translate"><img src="/images/tools/fulltext.png" :alt="'Access to the full text'|translate"></a>&nbsp;<a :href="fulltextUrl" target="_blank" :title="'Access to the full text'|translate"><translate>Access to the full text</translate></a>
            </li>
            <!-- View -->
            <li class="list-inline-item" v-if="fulltextUrl === '' && viewUrl !== ''">
                <a :href="viewUrl" target="_blank" rel="nofollow" :title="'View'|translate"><img src="/images/tools/view.png" :alt="'View'|translate"></a>&nbsp;<a :href="viewUrl" target="_blank" :title="'View'|translate"><translate>View</translate></a>
            </li>
            <!-- Libraries -->
            <li class="list-inline-item" v-if="librariesUrl !== ''">
                <a :href="librariesUrl" target="_blank" rel="nofollow" :title="'Libraries catalogue'|translate"><img src="/images/tools/catalog.png" :alt="'Libraries catalogue'|translate"></a>&nbsp;<a :href="librariesUrl" target="_blank" :title="'Libraries catalogue'|translate"><translate>Libraries catalogue</translate></a>
                <a :href="librariesUrl" target="_blank" rel="nofollow" :title="'Libraries catalogue'|translate"><img src="/images/tools/catalog.png" :alt="'Libraries catalogue'|translate"></a>&nbsp;<a :href="librariesUrl" target="_blank" :title="'Libraries catalogue'|translate"><translate>Libraries catalogue</translate></a>
            </li>
            <!-- BIBText -->
            <li class="list-inline-item" v-if="bibtex">
                <a href="javascript:void(0);" rel="nofollow" :title="'BIBTex'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'bibtex')"><img src="/images/tools/bibtex.png" :alt="'BIBTex'|translate"></a>&nbsp;<a href="javascript:void(0);" :title="'BIBTex'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'bibtex')"><translate>BIBTex</translate></a>
            </li>
            <!-- RIS -->
            <li class="list-inline-item" v-if="ris">
                <a href="javascript:void(0);" rel="nofollow" :title="'Reference managers'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'RIS')"><img src="/images/tools/ris.png" :alt="'Reference managers'|translate"></a>&nbsp;<a href="javascript:void(0);" :title="'Reference managers'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'RIS')"><translate>Reference managers</translate></a>
            </li>
            <!-- Addthis -->
            <li class="list-inline-item">
                <a href="http://www.addthis.com/bookmark.php?v=300&pubid=ra-504710d1176e7d1f" class="addthis_button" :title="'Share'|translate" :addthis:url="getItemURL(item)" :addthis:title="getItemFieldValue(item, 'title', $language.current, false)">
                    <img src="/images/tools/share.png" :alt="'Share'|translate">&nbsp;<translate>Share</translate>
                </a>
            </li>
            <!-- QR -->
            <li class="list-inline-item">
                <a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><img src="/images/tools/qr.png" :alt="'QR Code'|translate"></a>&nbsp;<a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><translate>QR Code</translate></a>
            </li>
        </ul>
        <ul class="list-inline" v-if="item.type === 'journal'">
            <!-- Libraries -->
            <li class="list-inline-item" v-if="librariesUrl !== ''">
                <a :href="librariesUrl" target="_blank" rel="nofollow" :title="'Libraries catalogue'|translate"><img src="/images/tools/catalog.png" :alt="'Libraries catalogue'|translate"></a>&nbsp;<a :href="librariesUrl" target="_blank" :title="'Libraries catalogue'|translate"><translate>Libraries catalogue</translate></a>
            </li>
            <!-- Addthis -->
            <li class="list-inline-item">
                <a href="http://www.addthis.com/bookmark.php?v=300&pubid=ra-504710d1176e7d1f" class="addthis_button" :title="'Share'|translate" :addthis:url="getItemURL(item)" :addthis:title="getItemFieldValue(item, 'title', $language.current, false)">
                    <img src="/images/tools/share.png" :alt="'Share'|translate">&nbsp;<translate>Share</translate>
                </a>
            </li>
            <!-- QR -->
            <li class="list-inline-item">
                <a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><img src="/images/tools/qr.png" :alt="'QR Code'|translate"></a>&nbsp;<a href="javascript:void(0);" v-b-modal.profile-qr :title="'QR Code'|translate"><translate>QR Code</translate></a>
            </li>
        </ul>
        <b-modal id="profile-info" :title="'Information'|translate" ok-only :ok-title="'Close'|translate">
            <span v-if="item.type === 'person'">{{ $gettext('personProfileInformation') }}</span>
            <span v-if="item.type === 'organization'">{{ $gettext('organizationProfileInformation') }}</span>
            <span v-if="item.type === 'journal'">{{ $gettext('journalProfileInformation') }}</span>
            <br /><br />
            {{ $gettext('Links to this record:') }}<br /><br />
            <span v-for="(url, index) in urls" :key="'item.' + item.id + '.accessURL.' + index">{{ url[0] }}<br /></span>
        </b-modal>
        <b-modal id="profile-qr" :title="'QR Code'|translate" ok-only :ok-title="'Close'|translate">
            <div class="text-center">
                <VueQrcode :value="qrCodeText"></VueQrcode>
            </div>
        </b-modal>
    </div>
</template>

<script>
    import VueQrcode from 'vue-qrcode';

    export default {
        name: 'item-profile-tools',
        props: ['item', 'fields'],
        components: { VueQrcode },
        data() {
            return {
                orcidUrl: '',
                scopusUrl: '',
                wokUrl: '',
                contactUrl: '',
                aprenUrl: '',
                librariesUrl: '',
                fulltextUrl: '',
                viewUrl: '',
                urls: [],
                qrCodeText: '',
                doiValue: '',
                bibtex: true,
                ris: true
            }
        },
        mounted() {
            this.prepareTools();
            this.prepareURLs();
            this.prepareQRCodeText();
        },
        methods: {
            /**
             * Prepare item tools to show in profile
             */
            prepareTools() {
                if (this.item.type === 'person') {
                    // Prepare tools data
                    let field = this.fields.find(f => f.view.format === 'orcid');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.orcidUrl = this.$store.state.config.orcidURL.replaceAll('{orcid}', value);
                    }

                    field = this.fields.find(f => f.view.format === 'scopus');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.scopusUrl = this.$store.state.config.scopusURL.replaceAll('{scopusId}', value);
                    }

                    field = this.fields.find(f => f.view.format === 'isiwok');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.wokUrl = this.$store.state.config.wokURL.replaceAll('{isiwok}', value);
                    }

                    field = this.fields.find(f => f.view.format === 'personId');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.checkAprenProfile(value);
                    }

                    let value = this.getItemFieldValue(this.item, 'title', this.$language.current, false);
                    if (value !== null) this.librariesUrl = this.$store.state.config.libraryCatalogAuthorURL.replaceAll('{author}', value);
                } else if (this.item.type === 'organization') {
                    // Prepare tools data
                    let field = this.fields.find(f => f.view.format === 'organizationId');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.contactUrl = this.$store.state.config.organizationContactURL.replaceAll('{organizationId}', value);
                    }
                } else if (this.item.type === 'activity') {
                    // Prepare tools data
                    if (this.item.file !== null && this.item.file.length > 0) this.fulltextUrl = this.item.file[0].split('##')[1];

                    // View and DOI
                    let field = this.fields.find(f => f.view.format === 'doi');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) {
                            this.viewUrl = this.$store.state.config.doiURL.replaceAll('{doi}', value);
                            this.doiValue = value;
                        } else {
                            value = this.getItemFieldValue(this.item, 'repositoryUrl', this.$language.current, false);
                            if (value !== null) this.viewUrl = value;
                            else {
                                value = this.getItemFieldValue(this.item, 'url', this.$language.current, false);
                                if (value !== null) this.viewUrl = value;
                            }
                        }
                    }

                    field = this.fields.find(f => f.view.format === 'isbnIssn');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.librariesUrl = this.$store.state.config.libraryCatalogISBNISSNURL.replaceAll('{isbnIssn}', value);
                    }
                } else if (this.item.type === 'journal') {
                    // Prepare tools data
                    let field = this.fields.find(f => f.view.format === 'isbnIssn');
                    if (field !== undefined) {
                        let value = this.getItemFieldValue(this.item, field.name, this.$language.current, false);
                        if (value !== null) this.librariesUrl = this.$store.state.config.libraryCatalogISBNISSNURL.replaceAll('{isbnIssn}', value);
                    }
                }
            },
            /**
             * Prepare item URLs
             */
            prepareURLs() {
                let urls = this.item.accessURL;
                urls = urls.map(u => u.split('##'));
                urls.sort(function (a, b) {
                    return a[1] > b[1] ? 1 : -1;
                });
                this.urls = urls;
            },
            /**
             * Prepare QR Code Text
             */
            prepareQRCodeText() {
                let text = '';
                if (this.item.type === 'person' || this.item.type === 'organization') {
                    text = 'BEGIN:VCARD\n';
                    text += 'VERSION:3.0\n';
                    text += 'N:' + this.getItemFieldValue(this.item, 'title', this.$language.current, false) + '\n';
                    if (this.item.image !== undefined) text += 'PHOTO;VALUE=URL;TYPE=JPG:' + this.item.image + '\n';

                    let email = this.getItemFieldValue(this.item, 'email', this.$language.current, false)
                    if (email !== null) text += 'EMAIL: ' + email + '\n';

                    let address = this.getItemFieldValue(this.item, 'address', this.$language.current, false)
                    if (address !== null) text += 'ADR;TYPE=WORK:' + address + '\n';

                    let researchGroup = this.getItemFieldValue(this.item, 'researchGroup', this.$language.current, false);
                    if (researchGroup !== null) text += 'ORG:' + researchGroup.split('##')[0] + '\n';

                    text += 'URL:' + this.urls[0][0] + '\n';
                    text += 'END:VCARD';
                } else text = this.getItemURL(this.item);

                this.qrCodeText = text;
            },
            /**
             * Checks if exists a profile in apren
             */
            checkAprenProfile(personId) {
                let vm = this;
                this.axios.get(this.$store.state.config.aprenProfileQueryURL.replace('{personId}', personId))
                    .then(response => {
                        // Check response
                        if (response.data.response && response.data.response.numFound > 0) {
                            let result = response.data.response.docs[0];
                            vm.aprenUrl = result.aprenUrl;
                        }
                    })
                    .catch(() => {
                        // Ignore error
                    });

            }
        }
    }
</script>
