<!--
--------------------------------------------------------------------------
   ItemParticipants.vue
--------------------------------------------------------------------------

    Component to print the activity participants

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-participants" v-if="participants.length > 0">
        <h3 v-translate>Participants</h3>
        <ul class="triangle-list">
            <li v-for="(participant, index) in participants" :key="'item.' + item.id + '.participant.' + index">
                <router-link :to="'/' + participant[1]" v-if="participant[1] !== undefined && participant[1] !== '0'">{{ participant[0] }} ({{ $gettext('ROLE_' + participant[2].toUpperCase().replaceAll(' ', '')) }}<span v-if="participant[4] !== undefined && participant[4].trim() !== '' && participant[4] !== 'Universitat Politècnica de Catalunya'"> - {{ participant[4] }}</span>)</router-link>
                <span v-else>{{ participant[0] }} ({{ $gettext('ROLE_' + participant[2].toUpperCase().replaceAll(' ', '')) }}<span v-if="participant[4] !== undefined && participant[4].trim() !== '' && participant[5] !== 'Universitat Politècnica de Catalunya'"> - {{ participant[4] }}</span>)</span>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'item-participants',
        props: ['item'],
        data() {
            return {
                participants: []
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Get and sort item participants
                let participants = this.getItemFieldValue(this.item, 'person', this.$language.current, true);
                participants = participants.map(p => p.split('##'));
                participants.forEach(p => {
                    this.checkItem(p[1]);
                });
                this.participants = participants;
            },
            /**
             * Check if item exists
             */
            checkItem(itemId) {
                // Get names of identifier fields
                let identifiers = [...new Set(this.$store.state.fields.filter(f => f.ident !== 0).map(f => {
                    if (this.specialFields.includes(f.name)) return f.name;
                    else return this.getFieldname(f.name, f.itemType);
                }))];

                // Prepare search query
                let formData = { query: ['accessURL_str:(' + this.filterQuery(this.$store.state.config.URL + '/' + itemId + '##*') + ' OR ' + this.filterQuery(this.$store.state.config.URL + '/' + itemId + '##*') + ')'], facets: [], page: { pageIdx: 0, pageSize: 2 }, sort: 'subtype desc' };
                identifiers.forEach(i => formData.query.push(i + '_str:"' + this.filterQuery(itemId) + '"'));
                formData.query = formData.query.join(' OR ');

                // Search items
                let vm = this;
                this.axios.post(this.apiURL + '/items/search', formData)
                    .then(response => {
                        // Item not found
                        if (response.data.content.length === 0) {
                            let index = vm.participants.findIndex(p => p[1] === itemId);
                            if (index !== -1) {
                                let personData = vm.participants[index];
                                personData[1] = '0';
                                vm.participants.splice(index, 1, personData);
                            }
                        }
                    })
                    .catch(error => {
                        this.processError(error);
                    });
            }
        },
        watch: {
            /**
             * Refresh data when item changes
             */
            item() {
                this.init();
            }
        }
    }
</script>