<!--
--------------------------------------------------------------------------
   ItemNews.vue
--------------------------------------------------------------------------

    Component to print the news of an item

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-news" v-if="news.length > 0">
        <b-card no-body class="overflow-hidden mb-3" v-for="(n, index) in news" :key="'news.' + index">
            <b-row no-gutters>
                <b-col md="2" v-if="n.image !== null">
                    <a :href="n.url" target="_blank" rel="nofollow" class="not-icon-after"><b-card-img :src="n.image" :alt="n.title" class="rounded-0 news-image"></b-card-img></a>
                </b-col>
                <b-col :md="n.image !== null ? 10 : 12">
                    <b-card-body>
                        <b-card-title>
                            <a :href="n.url" target="_blank" rel="nofollow">{{ n.title }}</a>
                        </b-card-title>
                        <b-card-text>
                            <p v-html="n.text"></p>
                            <div class="text-right small" style="font-weight: 300;">{{ $gettext('Publication date: ') }}: {{ n.publicationDate }}</div>
                        </b-card-text>
                    </b-card-body>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
    export default {
        name: 'item-news',
        props: ['item'],
        data() {
            return {
                news: []
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            /**
             * Inits news
             */
            init() {
                // Get item news
                let news = this.getItemFieldValue(this.item, 'news', this.$language.current, true);

                // Process news if exists
                if (news !== null) {
                    news.forEach(n => {
                        let newsParts = n.split('##');
                        if (this.news.find(n => n.title === newsParts[0]) === undefined) {
                            this.news.push({
                                title: newsParts[0],
                                text: newsParts[1],
                                url: newsParts[2],
                                publicationDate: newsParts[3],
                                image: newsParts.length > 4 ? newsParts[4] : null
                            });
                        }
                    });

                    // Sort news by publication date desc
                    news.sort((a, b) => {
                        if (a.publicationDate > b.publicationDate) return -1;
                        else return 1;
                    });

                    // Tell parent to show news tab
                    this.$root.$emit('showNews');
                }
            },
        },
        watch: {
            item() {
                this.init();
            }
        }
    }
</script>