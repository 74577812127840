<!--
--------------------------------------------------------------------------
   ItemCollaborationNetwork.vue
--------------------------------------------------------------------------

    Component to print the statistics of an item

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-collaboration-network">
        <iframe id="knowledge-maps" :src="$store.state.config.URL + '/knowledge-maps/?type=researcher&item=' + item.id + '&locale=ca'" v-if="item.type === 'person'"></iframe>
        <iframe id="knowledge-maps" :src="$store.state.config.URL + '/knowledge-maps/?type=groupResearchers&item=' + item.id + '&locale=ca'" v-if="item.type === 'organization'"></iframe>
    </div>
</template>

<script>
    export default {
        name: 'item-collaboration-network',
        props: ['item']
    }
</script>