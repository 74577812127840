<!--
--------------------------------------------------------------------------
   Simulation.vue
--------------------------------------------------------------------------

    Component to build simulation page

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div id="simulation" v-if="simulation !== null">
        <loading v-if="loading"></loading>
        <h1>{{ simulation.title }}</h1>

        <!-- Simulation data -->
        <b-card id="simulation-data">
            <b-card-text>
                <dl class="dl-horizontal">
                    <dt>
                        <span>{{ $gettext('Creation date') }}</span>
                    </dt>
                    <dd v-html="simulation.creationDate"></dd>
                    <dt>
                        <span>{{ $gettext('Components') }}</span>
                    </dt>
                    <dd>
                        <p v-for="(component, index) in simulation.components" :key="'component.' + index">{{ component.title }} ({{ $gettext(component.type) }})</p>
                    </dd>
                </dl>
            </b-card-text>
        </b-card>

        <!-- Scientific production -->
        <items :item="null" :section="null" :simulation="simulation" :searchType="null" :showFeatured="false" :featuredDefault="false" :showTitle="true"></items>

    </div>
</template>

<script>
    import Loading from '@/components/Loading';
    import Items from "@/components/Items";

    export default {
        name: 'item',
        components: { Loading, Items },
        data() {
            return {
                loading: true,
                simulation: null,
            }
        },
        mounted() {
            // Set base path
            this.$store.commit('setCurrentPath', '/simulations');

            this.init();
        },
        methods: {
            /**
             * Inits data
             */
            init() {
                // Get simulation id
                let simulationId = this.$route.params.simulationId.split('/');

                // Find simulation
                this.findSimulation(simulationId[0]);
            },
            /**
             * Finds simulation by string id
             */
            findSimulation(simulationId) {
                // Check if user is authenticated
                this.axios.get(this.apiURL + '/users/getUserAuth')
                    .then(() => {
                        // Search simulation
                        this.axios.get(this.apiURL + '/users/findSimulation?query=' + simulationId)
                            .then(response => {
                                // Get simulation data
                                response.data.components = JSON.parse(response.data.components);
                                this.simulation = response.data;

                                // Set breadcrumbs
                                this.setBreadcrumbs();

                                // Set metadata
                                this.setMetadata(this.simulation.title + ' | ' + this.$gettext('Simulations') + ' | ' + this.$gettext(this.$store.state.metadata.title), this.simulation.title, this.$gettext('simulation') + ',' + this.$gettext(this.$store.state.metadata.keywords));

                                this.loading = false;
                            })
                            .catch(error => {
                                // If not found, redirecto to not found page
                                if (error.response && error.response.status === 404) this.$router.replace({path: '/notFound'});
                                else this.processError(error);
                            });
                    })
                    .catch(() => {
                        // Redirect to login endpoint
                        window.location = this.apiURL + '/users/login?returnUrl=' + encodeURI(location.href);
                    });

            },
            /**
             * Sets the breadcrumbs
             */
            setBreadcrumbs() {
                let breadcrumbs = [{ text: this.$gettext('Home'), to: '/' }];

                // Add item
                breadcrumbs.push({ text: this.simulation.title, active: true });

                // Send breadcrumbs to store
                this.$store.commit('setBreadcrumbs', breadcrumbs);
            }
        },
        watch: {
            /**
             * Monitoring route change to update parameters and search results
             */
            $route(from, to) {
                // If simulation id changes, then re-init data
                if (from.params.simulationId !== to.params.simulationId) this.init();
            }
        }
    }
</script>